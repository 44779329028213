import * as React from 'react';
import {Typography} from "@mui/material";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Box from "@mui/material/Box";
import {CUSTOM_ORDER_STATUSES, CUSTOM_ORDER_VERSION_STATUSES} from "../../utils/helpers";
import { blueGrey, blue, orange, red, green } from '@mui/material/colors';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const SubmissionInfo = ({ currentUser, customOrder}) => {
    const getBackgroundColor = (status) => {
        switch (status) {
            case CUSTOM_ORDER_STATUSES.SUBMITTED:
                return blueGrey[500];
            case CUSTOM_ORDER_STATUSES.PROCESSING:
                return blue[500];
            case CUSTOM_ORDER_STATUSES.WAITING_FEEDBACK:
                return orange[500];
            case CUSTOM_ORDER_STATUSES.FEEDBACK_SUBMITTED:
                return blueGrey[500];
            case CUSTOM_ORDER_STATUSES.PROCESSING_FEEDBACK:
                return blue[500];
            case CUSTOM_ORDER_STATUSES.CANCELED:
                return red[500];
            default:
                return green[500];
        }
    };

    const customOrderDate = customOrder.id ? dayjs.utc(customOrder.created_at).tz(currentUser.timezone).format('MM/DD/YYYY') : '-';

    return (
        <Paper
            variant={'outlined'}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 600,
                    padding: '16px'
                }}
            >
                Submission ID: {customOrder.generated_id || '-'}
            </Typography>

            <Divider />

            <Stack
                sx={{
                    padding: '8px 16px'
                }}
                spacing={1}
            >
                <Stack>
                    <Typography color={'secondary'}>
                        Reference #
                    </Typography>
                    <Typography fontWeight={600}>
                        {customOrder.reference_number || '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Submit Date
                    </Typography>
                    <Typography fontWeight={600}>
                        {customOrderDate}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Submitted By
                    </Typography>
                    <Typography fontWeight={600}>
                        {customOrder.id ? `${customOrder.user?.first_name} ${customOrder.user?.last_name}` : '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Store Location
                    </Typography>
                    <Typography fontWeight={600}>
                        {customOrder.store ? customOrder.store.name : '-'}
                    </Typography>
                </Stack>

                <Divider variant="middle" component="div" />

                <Box>
                    <Typography color={'secondary'}>
                        Submission Status
                    </Typography>
                    <Box
                        sx={{
                            display: 'inline-block',
                            padding: '1px 4px',
                            backgroundColor: getBackgroundColor(customOrder.status),
                            color: '#fff',
                            borderRadius: '4px',
                            textAlign: 'center',

                        }}
                    >
                        {customOrder.status?.split(' ')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                        }
                    </Box>
                </Box>

                <Divider variant="middle" component="div" />

                <Stack>
                    <Typography color={'secondary'}>
                        Placed Order ID
                    </Typography>
                    <Typography fontWeight={600}>
                        {customOrder.order_id ?
                            <Link
                                component={RouterLink}
                                to={`/order-details/${customOrder.order_id}`}
                            >
                            {customOrder.order?.generated_order_id}
                        </Link> : '-'
                        }
                    </Typography>
                </Stack>

            </Stack>
        </Paper>
    );
};

export default SubmissionInfo;