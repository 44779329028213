import * as React from 'react';

import Typography from '@mui/material/Typography';
import MainCard from "../../../components/MainCard";
import useTheme from "@mui/material/styles/useTheme";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import CustomerDeliveryAddress from "./CustomerDeliveryAddress";
import {getJsonObject} from "../../../utils/helpers";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import WarningSubmitCancelDialog from "./WarningSubmitCancelDialog";


const ShippingOption = ({title, customerData, address, isDefault = false, isSelected = false, onClick, openEditDialogHandler, openClearCustomerDataDialogHandler}) => {
    const theme = useTheme();

    const sxObject = {
        '&:hover': {
            borderColor:  theme.palette.primary.light
        },
        cursor: 'pointer',
        position: 'relative',
    };

    if (isSelected) {
        sxObject.borderColor = theme.palette.primary.light;
    }

    return (
        <MainCard
            onClick={onClick}
            border={true}
            boxShadow
            shadow={theme.customShadows.z1}
            sx={sxObject}
        >
            <Typography
                variant="h5"
                sx={{
                    textAlign: !address ? 'center' : 'none',
                    marginBottom: !address ? '0' : '16px',
                }}
            >
                {title}
                {isDefault &&
                    <Chip
                        label="Default"
                        variant="light"
                        color="primary"
                        sx={{
                            borderRadius: '16px',
                            height: '20px',
                            ml: '8px',
                            '& > span': {
                                px: 1
                            }
                        }}
                    />
                }
            </Typography>

            {customerData?.first_name &&
                <Box
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        top: '16px'
                    }}
                >
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={openClearCustomerDataDialogHandler}
                        sx={{margin: '0 8px'}}
                    >
                        Clear
                    </Button>

                    <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={openEditDialogHandler}
                        sx={{margin: '0 8px'}}
                    >
                        Edit
                    </Button>
                </Box>
            }

            {address &&
                <Typography variant="caption" color="text.secondary">
                    {customerData?.first_name &&
                        <>
                            {customerData.first_name} {customerData.last_name} <br/>
                        </>
                    }

                    {address.address_line_1 ? <>{address.address_line_1}<br/></> : null}
                    {address.address_line_2 ? <>{address.address_line_2}<br/></> : null}
                    {address.city ? <>{address.city}<br/></> : null}
                    {address.state ? <>{address.state}</> : null}
                    {address.state && address.postal_code && <>{', '}</>}
                    {address.postal_code ? <>{address.postal_code}<br/></> : null}
                    {address.country ? <>{address.country}<br/></> : null}
                </Typography>
            }
        </MainCard>
    )
};

export default function ShippingDetails({ activeStore, shippingAddress, customerData, changeCustomerDataHandler, changeRecipientHandler }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openClearCustomerDataDialog, setOpenClearCustomerDataDialog] = React.useState(false);

    const countryShippingAddress = activeStore?.company?.shipping_address;
    const storeShippingAddress = activeStore?.shipping_address;

    return (
        <>
            <MainCard
                title="Shipped Details"
                border={false}
                boxShadow
                shadow={theme.customShadows.z1}
                sx={{ height: '100%' }}
            >
                <Stack spacing={2}>
                    <ShippingOption
                        title={'Ship To Store'}
                        isDefault={true}
                        address={getJsonObject(storeShippingAddress)}
                        isSelected={shippingAddress === storeShippingAddress}
                        onClick={() => {
                            changeRecipientHandler('store');
                        }}
                    />
                    <ShippingOption
                        title={'Ship To Company Office'}
                        address={getJsonObject(countryShippingAddress)}
                        isSelected={shippingAddress === countryShippingAddress}
                        onClick={() => {
                            changeRecipientHandler('company');
                        }}
                    />

                    {typeof (customerData) !== 'undefined' &&
                        <ShippingOption
                            title={'Ship To Customer'}
                            customerData={customerData}
                            address={customerData.address}
                            isSelected={shippingAddress === JSON.stringify(customerData.address) || openDialog}
                            openEditDialogHandler={() => setOpenDialog(true)}
                            openClearCustomerDataDialogHandler={() => setOpenClearCustomerDataDialog(true)}

                            onClick={() => {
                                if (!customerData.address) {
                                    setOpenDialog(true);
                                } else {
                                    changeCustomerDataHandler(customerData);
                                }
                            }}
                        />
                    }
                </Stack>
            </MainCard>

            {openDialog &&
                <CustomerDeliveryAddress
                    closeHandler={() => {setOpenDialog(false)}}
                    customerData={customerData}
                    setCustomerDataHandler={(value) => {
                        changeCustomerDataHandler(value);
                    }}
                />
            }

            {openClearCustomerDataDialog &&
            <WarningSubmitCancelDialog
                content={'Are you sure you want to clear the customer\'s shipping address?'}
                actionButtonText={'Clear Address'}
                closeHandler={() => {setOpenClearCustomerDataDialog(false)}}
                actionHandler={() => {
                    changeCustomerDataHandler({});
                    setOpenClearCustomerDataDialog(false);
                }}
            />
            }
        </>
    );
}
