import * as React from 'react';
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";

const SharePresentationLinkDialog = ({ link, closeHandler }) => {
    const theme = useTheme();
    const inputRef = React.useRef(null);
    const [copied, setCopied] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);

    const copyLinkHandler = async () => {
        const input = inputRef?.current?.querySelector('input');
        try {
            await navigator.clipboard.writeText(input.value);
            setCopied(true);
            setClicked(true);

            setTimeout(() => setClicked(false), 50);

        } catch (err) {
            alert("Error: " + err);
        }
    };

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
            maxWidth={'xs'}
        >
            <DialogContent>
                <Stack
                    spacing={3}
                >
                    <Typography
                        variant={'h3'}
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        Share This Presentation Link
                    </Typography>

                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Paper
                            component="form"
                            variant="outlined"
                            sx={{
                                p: '0 4px',
                                display: 'flex',
                                alignItems: 'center',
                                borderColor: theme.palette.text.secondary,
                                mb: copied ? 0 : 3
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                value={link}
                                readOnly={true}
                                inputProps={{ 'aria-label': 'presentation link' }}
                                ref={inputRef}
                            />
                            <Divider
                                sx={{
                                    height: 36,
                                    ml: 0.5,
                                    mr: 0.5,
                                    borderColor: theme.palette.text.secondary
                                }}
                                orientation="vertical"
                            />
                            <IconButton
                                sx={{
                                    p: '10px',
                                    color: clicked ? theme.palette.success.light : theme.palette.secondary.main,
                                    transition: !clicked ? 'color 2s ease' : ''
                                }}
                                aria-label="directions"
                                onClick={copyLinkHandler}
                            >
                                <ContentCopyOutlinedIcon />
                            </IconButton>
                        </Paper>


                        {copied &&
                            <Typography
                                variant={'body1'}
                                sx={{
                                    mt: '2px',
                                    color: theme.palette.success.dark
                                }}
                            >
                                Copied!
                            </Typography>
                        }
                    </Box>

                    <Typography
                        variant={'body1'}
                        sx={{
                            textAlign: 'center',
                            color: theme.palette.text.secondary,
                        }}
                    >
                        Click the clipboard icon to the right to copy the link and share it via email or SMS
                    </Typography>

                    <Button
                        onClick={closeHandler}
                        variant={'outlined'}
                    >
                        Close
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>);
};

export default SharePresentationLinkDialog;