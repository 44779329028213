import customOrdersService from "../../services/customOrdersService";
import {
    setPendingStatus,
    setReadyStatus,
    setCustomOrdersItems,
    setCustomOrderItem,
    setFilters,
    setFormsConfig
} from "../reducers/customOrders";
import { dispatch } from "../index";

export const storeCustomOrderAction = async (data) => {
    dispatch(setPendingStatus());

    await customOrdersService.storeCustomOrder(data);

    dispatch(setReadyStatus());
};

export const getCustomOrdersAction = async (data) => {
    dispatch(setPendingStatus());

    const response = await customOrdersService.getCustomOrders(data);

    dispatch(setCustomOrdersItems(response.data.data));
    dispatch(setReadyStatus());
};

export const getCustomOrderAction = async (customOrderId) => {
    dispatch(setPendingStatus());

    const response = await customOrdersService.getCustomOrder(customOrderId);

    dispatch(
        setCustomOrderItem({
            custom_order: response.data.data['custom_order']
        })
    );
    dispatch(setReadyStatus());
};

// export const updateOrderStatusAction = async (id, status) => {
//     dispatch(setPendingStatus());
//
//     await customOrdersService.updateOrderStatus(id, status);
//
//     dispatch(setReadyStatus());
// };

export const convertCustomOrderToOrderAction = async (id) => {
    dispatch(setPendingStatus());

    //await ordersService.convertCustomOrderToOrder(id);

    dispatch(setReadyStatus());
};

export const deleteCustomOrderAction = async (id) => {
    dispatch(setPendingStatus());

    await customOrdersService.deleteCustomOrder(id);

    dispatch(setReadyStatus());
};

export const getCustomOrderFormsConfigAction = async () => {
    dispatch(setPendingStatus());

    const response = await customOrdersService.getCustomOrderFormsConfig();

    dispatch(setFormsConfig(response.data.data));
    dispatch(setReadyStatus());
};

export const setFiltersAction = (data) => {
    dispatch(setFilters(data));
    dispatch(setReadyStatus());
};