export const getJsonObject = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return {};
    }
};

export const STATE_STATUSES = {
    READY: 'ready',
    PENDING: 'pending',
    ERROR: 'error',
    INIT:'init',
    DRAFT:'draft',
    CONNECTED:'connected'
};

export const ORDER_STATUSES = {
    SUBMITTED: 'submitted',
    IN_PROCESS: 'in process',
    PARTIALLY_COMPLETED: 'partially completed',
    COMPLETED: 'completed',
    CANCELED: 'canceled'
};

export const ORDER_DELIVERY_STATUSES = {
    FULFILLED: 'fulfilled',
    SHIPPED: 'shipped',
    DELIVERED: 'delivered',
    MULTI: '(Multi)',
    PRE_TRANSIT: 'pre_transit',
    TRANSIT: 'transit',
    RETURNED: 'returned',
    FAILURE: 'failure',
    UNKNOWN: 'unknown'
};

export const ORDER_ITEM_DELIVERY_STATUSES = {
    SHIPPED: 'shipped',
    PRE_TRANSIT: 'pre_transit',
    TRANSIT: 'transit',
    DELIVERED: 'delivered',
    RETURNED: 'returned',
    FAILURE: 'failure',
    UNKNOWN: 'unknown',
    FULFILLED: 'fulfilled'
};

export const ROLE_NAMES = {
    GLOBAL_ADMIN: 'Global Admin',
    COMPANY_ADMIN: 'Company Admin',
    FINANCIAL_MANAGER: 'Financial Manager',
    SALES_MANAGER: 'Sales Manager',
    SALESPERSON: 'Salesperson'
};

export const CUSTOM_ORDER_STATUSES = {
    SUBMITTED: 'submitted',
    PROCESSING: 'processing request',
    WAITING_FEEDBACK: 'waiting for feedback',
    FEEDBACK_SUBMITTED: 'feedback submitted',
    PROCESSING_FEEDBACK: 'processing feedback',
    CANCELED: 'canceled',
    ORDERED: 'ordered'
};

export const CUSTOM_ORDER_VERSION_STATUSES = {
    WAITING_FEEDBACK: 'waiting for feedback',
    FEEDBACK_SUBMITTED: 'feedback submitted',
    UNPUBLISHED: 'unpublished',
    APPROVED: 'approved',
    REJECTED: 'rejected'
};

export const prepareUrlParams = (filters) => {
    const combineSortingValues = (sortingValues) => {
        const strings = [];

        sortingValues.forEach(value => {
            strings.push(`${value.desc ? '-' : ''}${value.id}`);
        });

        return strings.join(',');
    };

    const params = {
        page: filters.pagination.page,
        per_page: filters.pagination.perPage,
    };

    if (filters.sorting?.length > 0) {
        params.sort = combineSortingValues(filters.sorting);
    }

    if (filters.orderStatus || filters.userStatus || filters.storeStatus || filters.productStatus || filters.customOrderStatus) {
        params.status = filters.orderStatus || filters.userStatus || filters.storeStatus || filters.productStatus || filters.customOrderStatus;
    }

    if (filters.globalFilter) {
        params.filter = filters.globalFilter;
    }

    if (filters.startDate) {
        params.start = filters.startDate;
    }

    if (filters.endDate) {
        params.end = filters.endDate;
    }

    if (filters.storeIds?.length > 0) {
        params.store_ids = filters.storeIds.join(',');
    }

    return params;
};

export const parseSortingParam = (param) => {
    const sortingValues = [];

    param?.split(',').forEach(item => {
        if (item[0] === '-'){
            sortingValues.push({id: item.substring(1), desc: true})
        } else {
            sortingValues.push({id: item, desc: false})
        }
    });

    return sortingValues;
};

export const getProductMainImage = (product) => {
    const media = getJsonObject(product.media).media;
    const images = media?.filter(obj => !obj.video);
    return images?.[0]?.original ?? '/no-image.png';
};
