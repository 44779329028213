import * as React from 'react';

// material-ui

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';

// project imports
import ReferenceNumber from './components/ReferenceNumber';

import MainCard from "../../components/MainCard";

// assets
import Avatar from "../../components/Avatar";
import {useSelector} from "react-redux";
import ShippingDetails from "./components/ShippingDetails";

import CancellationDialog from "./components/CancellationDialog";
import OrderItemCartEntity from "../../components/OrderItemCartEntity";
import {getCheckoutSettingsAction, setCheckoutSettingsAction} from "../../store/actions/checkout";
import {storeOrderAction} from "../../store/actions/orders";
import {getJsonObject, STATE_STATUSES} from "../../utils/helpers";

// project imports

import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import OrderCanceledDialog from "./components/OrderCanceledDialog";
import OrderCreatedDialog from "./components/OrderCreatedDialog";
import {getActiveStoreAction} from "../../store/actions/user";
import AlertComponent from "../../components/AlertComponent";
import TableHead from "@mui/material/TableHead";
import {TableRow} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import WarningSubmitCancelDialog from "./components/WarningSubmitCancelDialog";
import {deleteCartItemAction} from "../../store/actions/cart";

// ==============================|| PRODUCT - CHECKOUT MAIN ||============================== //

export default function CheckoutPage() {
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const activeStore = useSelector(store => store.stores.activeStore);
    const { items } = useSelector(store => store.cart);
    const checkoutSettings = useSelector(store => store.checkout.settings);

    const {status} = useSelector(store => store.orders);

    const customerData = React.useMemo(() => getJsonObject(checkoutSettings.customerData), [checkoutSettings.customerData]);
    const totalQuantity = React.useMemo(() => {return items.reduce((total, item) => {return total + item.quantity}, 0)}, [items]);

    const shippingAddress = checkoutSettings.shippingAddressObject === 'company' ?
        activeStore?.company.shipping_address : checkoutSettings.shippingAddressObject === 'store' ?
            activeStore?.shipping_address : JSON.stringify(customerData?.address || {});

    const recipient = checkoutSettings.shippingAddressObject === 'company' ?
        ({company: activeStore.company.shipping_name}) : checkoutSettings.shippingAddressObject === 'store' ?
            ({store: activeStore.shipping_name || activeStore.name }) :
                ({customer: {
                    first_name: customerData.first_name,
                    last_name: customerData.last_name
                }});

    const [openCancellationDialog, setOpenCancellationDialog] = React.useState(false);
    const [openDraftCreatedDialog, setOpenDraftCreatedDialog] = React.useState(false);
    const [openOrderCanceledDialog, setOpenOrderCanceledDialog] = React.useState(false);
    const [openOrderCreatedDialog, setOpenOrderCreatedDialog] = React.useState(false);
    const [orderType, setOrderType] = React.useState('');
    const [selectedItemId, setSelectedItemId] = React.useState(null);

    const canCreateOrder = items?.length > 0 && shippingAddress?.length > 2;

    const createOrder = async (isDraft = false) => {
        const decodedAddress = getJsonObject(shippingAddress);
        const entries = Object.entries(recipient);

        const data = {
            recipient: JSON.stringify({type: entries[0][0], value: entries[0][1]}),
            address_line_1: decodedAddress.address_line_1 || null,
            address_line_2: decodedAddress.address_line_2 || null,
            city: decodedAddress.city || null,
            state: decodedAddress.state || null,
            postal_code: decodedAddress.postal_code || null,
            country: decodedAddress.country || null,

            is_draft: isDraft,
            store_id: activeStore.id,
        };

        if (checkoutSettings.referenceNumber?.length > 0) {
            data.reference_number = checkoutSettings.referenceNumber;
        }

        await storeOrderAction(data);
    };

    const createOrderHandler = async (createDraft = false) => {
        try {
            setOrderType('');
            setOpenCancellationDialog(false);

            await createOrder(createDraft);
            setOrderType(createDraft ? 'draft' : 'regular');
        }  catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    React.useEffect(() => {
        const handleFunction = async () => {
            if (orderType && status === STATE_STATUSES.READY) {
                try {
                    await setCheckoutSettingsAction(activeStore.id, currentUser.id, {
                        reference_number: '',
                        customer_data: '{}',
                        shipping_address_object: 'store'
                    });

                    (orderType === 'draft') ?
                        setOpenDraftCreatedDialog(true) :
                        setOpenOrderCreatedDialog(true);
                } catch (e) {
                    AlertComponent.error(e.response.data.message);
                }
            }
        };

        handleFunction();
    }, [orderType, status]);

    React.useEffect(() => {
        const getCheckoutSettings = async () => {
            try {
                await getActiveStoreAction();
                await getCheckoutSettingsAction(activeStore.id, currentUser.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        getCheckoutSettings();
    }, [activeStore.id, currentUser.id]);

    const changeReferenceNumberHandler = async (value) => {
        if (value === checkoutSettings.referenceNumber) {
            return;
        }

        try{
            await setCheckoutSettingsAction(activeStore.id, currentUser.id, {
                reference_number: value,
                customer_data: checkoutSettings.customerData,
                shipping_address_object: checkoutSettings.shippingAddressObject
            });
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const changeCustomerDataHandler = async (value) => {
        try {
            await setCheckoutSettingsAction(activeStore.id, currentUser.id, {
                reference_number: checkoutSettings.referenceNumber,
                customer_data: JSON.stringify(value),
                shipping_address_object: 'customer'
            });
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    const changeRecipientHandler = async (recipient) => {
        try{
            await setCheckoutSettingsAction(activeStore.id, currentUser.id, {
                reference_number: checkoutSettings.referenceNumber,
                customer_data: checkoutSettings.customerData,
                shipping_address_object: recipient
            });
        } catch (e) {
            AlertComponent.error(e.response.data.message);
        }
    };

    return (
        <DefaultLayout>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Stack spacing={2}>
                                <MainCard content={false}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ py: 2.5, pl: 2.5 }}>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography variant="subtitle1">Cart</Typography>
                                                <Avatar color="secondary" size="xs">
                                                    {totalQuantity}
                                                </Avatar>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    {totalQuantity > 0 &&
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell component={'th'}/>
                                                                <TableCell
                                                                    component={'th'}
                                                                    sx={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    MSRP
                                                                </TableCell>
                                                                <TableCell
                                                                    component={'th'}
                                                                    sx={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    Quantity
                                                                </TableCell>
                                                                <TableCell
                                                                    component={'th'}
                                                                    sx={{
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    Total Amount
                                                                </TableCell>
                                                                <TableCell component={'th'}/>
                                                            </TableRow>
                                                        </TableHead>
                                                    }
                                                    <TableBody>
                                                        {items.map((singleItem) => {
                                                            return (
                                                                <OrderItemCartEntity
                                                                    key={singleItem.id}
                                                                    singleItem={singleItem}
                                                                    isForDraftOrder={false}
                                                                    setSelectedItemId={setSelectedItemId}
                                                                />
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Stack spacing={3}>
                                <MainCard>
                                    <ReferenceNumber
                                        number={checkoutSettings.referenceNumber}
                                        changeHandler={changeReferenceNumberHandler}
                                    />
                                </MainCard>

                                <ShippingDetails
                                    shippingAddress={shippingAddress}
                                    activeStore={activeStore}
                                    customerData={customerData}
                                    changeCustomerDataHandler={changeCustomerDataHandler}
                                    changeRecipientHandler={changeRecipientHandler}
                                />

                                <Button
                                    disabled={!canCreateOrder}
                                    variant="contained"
                                    fullWidth
                                    onClick={() => {setOpenCancellationDialog(true)}}
                                >
                                    Submit Order
                                </Button>
                                <Button
                                    disabled={!canCreateOrder}
                                    variant="outlined"
                                    color='secondary'
                                    fullWidth
                                    onClick={async () => await createOrderHandler(true)}
                                >
                                    Save Order as Draft
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {selectedItemId &&
            <WarningSubmitCancelDialog
                content={'Are you sure you want to remove this item from the cart?'}
                actionButtonText={'Remove'}
                closeHandler={() => {setSelectedItemId(null)}}
                actionHandler={
                    async () => {
                        try {
                            await deleteCartItemAction(selectedItemId);
                            setSelectedItemId(null);
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }
                }
            />
            }

            {openCancellationDialog &&
                <CancellationDialog
                    mainText={'Order submission in progress...'}
                    bottomText={'Do not refresh or close this page until your order is submitted.'}
                    cancelButtonText={'Cancel Order'}
                    createOrderHandler={async () => await createOrderHandler(false)}
                    closeDialogHandler={() => {
                        setOpenCancellationDialog(false);
                        setOpenOrderCanceledDialog(true);
                    }}
                />
            }

            {openOrderCreatedDialog &&
                <OrderCreatedDialog
                    redirectPath={'/orders-history'}
                    mainText={'Your order has been placed!'}
                    bottomText={'Redirecting you to the orders history page.'}
                    showProgressBar={true}
                />
            }

            {openDraftCreatedDialog &&
                <OrderCreatedDialog
                    redirectPath={'/draft-orders'}
                    mainText={'Your draft order has been saved!'}
                    bottomText={'Redirecting you to the draft orders page.'}
                />
            }

            {openOrderCanceledDialog &&
                <OrderCanceledDialog
                    mainText={'Canceling order submission...'}
                    bottomText={'Redirecting you back to the checkout page.'}
                    closeDialogHandler={() => setOpenOrderCanceledDialog(false)}
                />
            }

        </DefaultLayout>
    );
}
