import * as React from 'react';
import {useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import SingleItem from "./SingleItem";
import {
    getCartItemsAction,
    deleteCartItemAction,
} from "../../../../store/actions/cart";
import {useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import WarningSubmitCancelDialog from "../../../../pages/checkout/components/WarningSubmitCancelDialog";
import AlertComponent from "../../../AlertComponent";

const CartWidget = ({ isOpened, setIsOpened }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const {status, items } = useSelector(store => store.cart);
    const activeStore = useSelector(store => store.stores.activeStore);
    const currentUser = useSelector(store => store.auth.authenticatedUser);
    const [selectedItemId, setSelectedItemId] = React.useState(null);

    React.useEffect(() => {
        const getItems = async () => {
            try {
                await getCartItemsAction(activeStore.id, currentUser.id);
            } catch (e) {
                AlertComponent.error(e.response.data.message);
            }
        };

        getItems();
    }, [activeStore.id, currentUser.id]);

    return (
        <>
            <Drawer
                anchor={'right'}
                open={isOpened}
                onClose={() => setIsOpened(false)}
            >
                <Box
                    sx={{
                        overflow: "hidden",
                        height: "100vh",
                        position: "relative",
                    }}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        position={"sticky"}
                        padding="16px"
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                margin: "10px",
                                flex: 1,
                                color: theme.palette.secondary.dark
                            }}
                            color={'secondary'}
                            onClick={() => setIsOpened(false)}
                        >
                            Hide Cart
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                margin: "10px",
                                flex: 1
                            }}
                            disabled={!items?.length}
                            onClick={() => {
                                setIsOpened(false);
                                navigate("/checkout");
                            }}
                        >
                            Checkout
                        </Button>
                    </Box>

                    <Box
                        sx={{
                            width: "25vw",
                            minWidth: "300px",
                            padding: "16px",
                            overflowY: "auto",
                            height: "calc(100vh - 110px)",
                        }}
                        role="presentation"
                    >
                        {items?.length > 0 &&
                            <div>
                                {
                                items.map(item => (
                                    <React.Fragment
                                        key={item.id}
                                    >
                                        <SingleItem
                                            item={item}
                                            setSelectedItemId={setSelectedItemId}
                                        />
                                        <Divider/>
                                    </React.Fragment>
                                ))
                            }
                            </div>
                        }

                        {!items?.length &&
                        <Box
                            ///width={'100%'}
                            height={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            flexWrap={'wrap'}
                        >
                            <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'flex-end'}
                            >
                                <img
                                    src={'/empty.png'} alt={'empty'}
                                    width={'75%'}
                                />
                            </Box>
                            <Typography
                                variant={'h3'}
                                sx={{
                                    width: '70%',
                                    textAlign: 'center',
                                    marginTop: '32px'
                                }}

                            >
                                There are no products in your cart.
                            </Typography>
                        </Box>
                        }
                    </Box>
                </Box>
            </Drawer>

            {selectedItemId &&
            <WarningSubmitCancelDialog
                content={'Are you sure you want to remove this item from the cart?'}
                actionButtonText={'Remove'}
                closeHandler={() => {setSelectedItemId(null)}}
                actionHandler={
                    async () => {
                        try {
                            await deleteCartItemAction(selectedItemId);
                            setSelectedItemId(null);
                        } catch (e) {
                            AlertComponent.error(e.response.data.message);
                        }
                    }
                }
            />
            }
        </>
    );
};

export default CartWidget;