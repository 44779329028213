import {authApi} from './apiService'

const getCustomOrders = async (params) => authApi.get(`custom-orders?${params}`);
const getCustomOrder = async (id) => authApi.get(`custom-orders/${id}`);
const storeCustomOrder = async (data) => authApi.post(`custom-orders/`, data);
const deleteCustomOrder = async (id) => authApi.delete(`custom-orders/${id}`);
const convertCustomOrderToOrder = async (id, data) => authApi.post(`custom-orders/${id}/convert`, data);
const getCustomOrderFormsConfig = async () => authApi.get('custom-orders/form-parameters');

const apiCalls = {
    getCustomOrders,
    getCustomOrder,
    storeCustomOrder,
    deleteCustomOrder,
    convertCustomOrderToOrder,
    getCustomOrderFormsConfig
};

export default apiCalls;