import { createSlice } from '@reduxjs/toolkit'
import {STATE_STATUSES} from "../../utils/helpers";

const filtersInitialState = {
    pagination: {
        page: 1,
        perPage: 25,
        total: 0,
        totalWasUpdated: false
    },

    sorting: [{id: 'created_at', desc: true}],
    customOrderStatus: null,
    globalFilter: null,
    startDate: null,
    endDate: null,
    storeIds: [],
    initialState: true
};

const initialState = {
    items: [],
    quantities: {
        'action required': 0,
        open: 0,
        closed: 0,
    },
    selectedItem: {},
    formsConfig: {},
    status: STATE_STATUSES.INIT,
    filters: {...filtersInitialState},
};

export const customOrdersSlice = createSlice({
    name: 'customOrders',
    initialState,
    reducers: {
        setPendingStatus: (state) => {
            state.status = STATE_STATUSES.PENDING;
        },

        setCustomOrdersItems: (state, action) => {
            state.items = action.payload['custom_orders'].data;

            state.quantities = action.payload['custom_orders_quantities'].reduce((acc, curr) => {
                acc[curr.custom_order_status] = curr.quantity;
                return acc;
            }, {...initialState.quantities});

            state.filters.pagination.totalWasUpdated =
                state.filters.pagination.total !== action.payload['custom_orders'].total;
            state.filters.pagination.total = action.payload['custom_orders'].total;
        },

        setReadyStatus: (state) => {
            state.status = STATE_STATUSES.READY;
        },

        setFilters: (state, action) => {
            if (action.payload.resetFilters) {
                state.filters = {...filtersInitialState};
            } else {
                state.filters = {
                    ...action.payload,
                    pagination: {
                        ...action.payload.pagination,
                        totalWasUpdated: false
                    }
                }
            }
        },

        setCustomOrderItem: (state, action) => {
            state.selectedItem = action.payload.custom_order;
        },

        setFormsConfig: (state, action) => {
            state.formsConfig = action.payload.form_parameters
        }
    },
});

export const {
    setPendingStatus,
    setReadyStatus,
    setFilters,
    setCustomOrdersItems,
    setCustomOrderItem,
    setFormsConfig
} = customOrdersSlice.actions;

export default customOrdersSlice.reducer;