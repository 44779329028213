import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";
import {useTheme} from "@mui/material";

const CancellationDialog = ({ mainText, bottomText, cancelButtonText, createOrderHandler, closeDialogHandler }) => {
    const theme = useTheme();
    const [secondsLeft, setSecondsLeft] = React.useState(5);

    React.useEffect(() => {
        const timer = setInterval(() => {
            if (secondsLeft === 0) {
                createOrderHandler();
            }
            else {
                setSecondsLeft((prev) => prev > 0 ? prev - 1 : prev);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [secondsLeft, createOrderHandler]);

    return (
        <Dialog
            open={true}
        >
            <Box
                padding={'32px'}
                textAlign={'center'}
                width={'400px'}
            >
                <Box
                    sx={{
                        margin: '16px auto 10px auto',
                        '& img': {
                            width: '80px'
                        }
                    }}
                >
                    <img src={'/warning.png'} alt={'warning'}/>
                </Box>

                <Typography
                    variant={'h4'}
                    sx={{
                        marginBottom: '16px'
                    }}
                >
                    {mainText}
                </Typography>

                <LinearProgress variant="determinate" value={100 / 5 * (5 - secondsLeft)} />

                <Typography
                    variant={'body1'}
                    sx={{
                        marginTop: '16px',
                        color: theme.palette.text.secondary
                    }}
                >
                    {bottomText}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{
                        margin: '16px auto 0 auto',
                        width: '180px'
                    }}
                    onClick={closeDialogHandler}
                >
                    {cancelButtonText}
                </Button>
            </Box>
        </Dialog>
    );
};

export default CancellationDialog;