import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

// third-party
import {
    flexRender,
    useReactTable,
    getExpandedRowModel,
    getCoreRowModel,
} from '@tanstack/react-table';

// project import
import ScrollX from '../../components/ScrollX';

import MainCard from "../../components/MainCard";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import HeaderSort from "../../components/HeaderSort";
import TablePagination from "../../components/TablePagination";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DebouncedInput from "../../components/DebouncedInput";
import {MobileDatePicker} from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from "@mui/material/Button";
import SelectStoresDropdown from "./SelectStoresDropdown";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import NoItemsComponent from "../../components/NoItemsComponent";
import {ROLE_NAMES} from "../../utils/helpers";

const RenderSubComponent = ({ row, subColumns, filters, productsLoading, showDrafts }) => {
    return (
        <MainCard
            title={`${showDrafts ? 'Draft' : 'Order'} Items`}
            content={false}
            sx={{ ml: { xs: 2.5, sm: 5, md: 6, lg: 10, xl: 12 } }}
        >
            <ReactTable {...{ columns: subColumns, data: row.original.products || [], productsLoading, isList: true, filters }} />
        </MainCard>
    );
};

const ReactTable = (
    {
        showDrafts, caption, columns, subColumns, data, quantities, loading, productsLoading, isList,
        paginationChangeHandler, filters, stores, storeClickHandler, setGlobalFilterHandler, setDateHandler,
        selectedOrdersStatusHandler, setSortingHandler, expanded, setExpandedHandler, setOpenExportCsvDialog
    }) => {

    const theme = useTheme();

    const currentUser = useSelector(store => store.auth.authenticatedUser);

    const groups = showDrafts ? ['all'] : ['all', 'open', 'closed'];

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting: filters.sorting,
            expanded
        },
        manualSorting: true,
        getRowCanExpand: () => true,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
        onSortingChange: setSortingHandler,
        onExpandedChange: setExpandedHandler,
    });

    const bottomBorderColor = alpha(theme.palette.secondary.light, 0.5);
    const backColor = 'white';

    if (isList && productsLoading) {
        return (
            <Table
                size="small"
            >
                <TableHead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableCell key={header.id} {...header.column.columnDef.meta}>
                                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {[0, 1, 2].map((item) => (
                        <TableRow key={item}>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((col) => (
                                <TableCell key={col}>
                                    <Skeleton animation="wave" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <MainCard
            content={false}
            {...(!isList && { title: caption })}
        >
            {!isList &&
            <>
                <Box sx={{p: 2.5, pb: 0, width: '100%'}}>
                    <Tabs
                        value={filters.orderStatus || 'all'}
                        onChange={(e, value) => {
                            selectedOrdersStatusHandler(value === 'all' ? null : value);
                        }}
                        sx={{borderBottom: 1, borderColor: 'divider'}}
                    >
                        {groups.map((status, index) => {
                            const label = status === 'all' ? quantities['open'] + quantities['closed'] : quantities[status];
                            let renderedChip;

                            switch (status) {
                                case 'all':
                                    renderedChip = <Chip color="secondary" label={loading ? '-' : label} size="small" variant="dark" />;
                                    break;
                                case 'open':
                                    renderedChip = <Chip color="primary" label={loading ? '-' :label} size="small" variant="main" />;
                                    break;
                                case 'closed':
                                default:
                                    renderedChip = <Chip color="warning" label={loading ? '-' :label} size="small" variant="main" />;
                                    break;
                            }

                            return (
                                <Tab
                                    key={index}
                                    label={status}
                                    value={status}
                                    icon={renderedChip}
                                    iconPosition="end"
                                />
                            )
                        })}
                    </Tabs>
                </Box>

                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between"
                       sx={{padding: 2.5}}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <DebouncedInput
                            value={filters.globalFilter ?? ''}
                            onFilterChange={setGlobalFilterHandler}
                            placeholder={`Search in orders...`}
                            size={'small'}
                            name={'searchInOrders'}
                            id={'search-in-orders'}
                        />

                        {[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER].includes(currentUser.role.name) &&
                            <SelectStoresDropdown
                                stores={stores}
                                selectedStoreIds={filters.storeIds}
                                storeClickHandler={storeClickHandler}
                                size={'small'}
                            />
                        }
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Stack direction="row" spacing={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Start Date"
                                    format="MM/DD/YYYY"
                                    sx={{
                                        width: "110px",
                                        '& > label:not(.MuiInputLabel-shrink)': {
                                            top: "2px",
                                            fontSize: "0.8rem"
                                        }
                                    }}
                                    value={filters.startDate ? dayjs.utc(filters.startDate).tz(currentUser.timezone) : null}
                                    onChange={(value) => {setDateHandler('startDate', value)}}
                                    slotProps={{ textField: { size: 'small' } }}
                                    maxDate={filters.endDate ? dayjs.utc(filters.endDate).tz(currentUser.timezone) : dayjs()}
                                />
                                <MobileDatePicker
                                    label="End Date"
                                    format="MM/DD/YYYY"
                                    sx={{
                                        width: "110px",
                                        '& > label:not(.MuiInputLabel-shrink)': {
                                            top: "2px",
                                            fontSize: "0.8rem"
                                        }
                                    }}
                                    value={filters.endDate ? dayjs.utc(filters.endDate).tz(currentUser.timezone) : null}
                                    onChange={(value) => {setDateHandler('endDate', value)}}
                                    slotProps={{ textField: { size: 'small' } }}
                                    minDate={filters.startDate ? dayjs.utc(filters.startDate).tz(currentUser.timezone) : null}
                                    maxDate={dayjs()}
                                />
                            </LocalizationProvider>
                            <Box>
                                <Button
                                    variant={'outlined'}
                                    size={'small'}
                                    onClick={() => {setDateHandler('clearAll')}}
                                >
                                    Clear
                                </Button>

                                {[ROLE_NAMES.GLOBAL_ADMIN, ROLE_NAMES.COMPANY_ADMIN, ROLE_NAMES.FINANCIAL_MANAGER].includes(currentUser.role.name) &&
                                    <Button
                                        sx={{
                                            marginLeft: '32px'
                                        }}
                                        variant={'outlined'}
                                        size={'small'}
                                        color={'secondary'}
                                        onClick={() => {
                                            setOpenExportCsvDialog(true)
                                        }}
                                    >
                                        Export CSV
                                    </Button>
                                }
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </>
            }

            <ScrollX>
                <TableContainer component={Paper}>
                    <Table
                        size="small"
                    >
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        const canSort = header.column.getCanSort();
                                        const isSorted = header.column.getIsSorted();

                                        if (header.column.columnDef.meta !== undefined && canSort) {
                                            Object.assign(header.column.columnDef.meta, {
                                                className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select'
                                            });
                                        }

                                        return (
                                            <TableCell
                                                key={header.id}
                                                {...header.column.columnDef.meta}
                                                onClick={header.column.getToggleSortingHandler()}
                                                {...(canSort &&
                                                    header.column.columnDef.meta === undefined && {
                                                        className: 'cursor-pointer prevent-select'
                                                    })}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Box
                                                            sx={{
                                                                cursor: 'pointer',
                                                                userSelect: 'none',
                                                                color: (isSorted === 'asc' && theme.palette.success.main) ||
                                                                    (isSorted === 'desc' && theme.palette.primary.main) ||
                                                                    'inherit'
                                                            }}
                                                        >
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                        </Box>
                                                        {canSort && <HeaderSort column={header.column} />}
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableHead>
                        {loading &&
                            <TableBody>
                                {[0, 1, 2].map((item) => (
                                    <TableRow key={item}>
                                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((col) => (
                                            <TableCell key={col}>
                                                <Skeleton animation="wave" />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                        {!loading &&
                            <TableBody>
                                {table.getRowModel().rows.map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow
                                            sx={isList ? {
                                                borderBottom: '2px solid',
                                                borderBottomColor: bottomBorderColor
                                            } : {}}
                                        >
                                            {row.getVisibleCells().map((cell) => (
                                                <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {row.getIsExpanded() && (
                                            <TableRow
                                                sx={{bgcolor: backColor, '&:hover': {bgcolor: `${backColor} !important`}}}>
                                                <TableCell colSpan={row.getVisibleCells().length}>
                                                    <RenderSubComponent {...{row, subColumns, filters, productsLoading, showDrafts}} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </ScrollX>

            {data.length === 0 && !loading &&
                <NoItemsComponent
                    caption={`No ${showDrafts ? 'Draft ' : ''} ${isList ? 'Products' : 'Orders'} Found.`}
                    verticalMargin={32}
                />
            }

            {!isList &&
            <>
                <Divider/>
                <Box sx={{p: 2}}>
                    <TablePagination
                        paginationChangeHandler={paginationChangeHandler}
                        paginationObject={filters.pagination}
                    />
                </Box>
            </>
            }
        </MainCard>
    );
};

export default ReactTable;