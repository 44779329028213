import * as React from "react";
import { Navigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {STATE_STATUSES} from "../utils/helpers";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { authenticatedUser, status } = useSelector(store => store.auth);
    const [loading, setLoading] = React.useState(true);
    const authToken = window.localStorage.getItem('auth_token');

    React.useEffect(() => {
        if ([STATE_STATUSES.READY, STATE_STATUSES.ERROR].includes(status)) {
           setLoading(false);
        }
    }, [status]);

    if (!authToken) {
        return (<Navigate to="/login" replace/>);
    }

    if (loading) {
        return null;
    }

    return authenticatedUser ? (<Component {...rest} />) : (<Navigate to="/login" replace/>);
};

export default PrivateRoute;