import * as React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Avatar from "./Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProductQuantity from "./ProductQuantity";
import IconButton from "./IconButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useTheme} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {generateSelectedOptionsWithValues} from "./dynamicForms/utils";
import {getProductMainImage} from "../utils/helpers";

const OrderItemCartEntity = ({ singleItem, setSelectedItemId, isForDraftOrder = false, isSelected = false, checkboxClickHandler = () => {} }) => {
    const theme = useTheme();
    const optionsWithValues = generateSelectedOptionsWithValues(singleItem);

    return (
        <TableRow
            sx={{
                '&:last-of-type td, &:last-of-type th': { border: 0 },
            }}
            selected={isSelected}
        >
            <TableCell>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Avatar
                            size="xxl"
                            variant="rounded"
                            color="secondary"
                            type="combined"
                            src={getProductMainImage(singleItem.product)}
                        />
                    </Grid>
                    <Grid item>
                        <Stack spacing={0}>
                            <Typography
                                variant="subtitle1"
                                color="text.primary"
                            >
                                {singleItem.product.title}
                            </Typography>
                            <Typography color="text.secondary">
                                {optionsWithValues.map(option => {
                                    let value = option.value;
                                    const isBoolean = typeof(value) === 'boolean';

                                    if (isBoolean) {
                                        value = value ? 'Yes' : 'No';
                                    }

                                    return (
                                        <Box
                                            component={'span'}
                                            key={option.title}
                                            display={'block'}
                                        >
                                            {option.title}: {value}
                                        </Box>
                                    )
                                })}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="center">
                <Stack alignItems="center">
                    {singleItem?.product_price && (
                        <Typography variant="subtitle1">
                            {(+singleItem.product_price)
                                .toLocaleString(
                                    'en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD'
                                    })
                            }
                        </Typography>
                    )}
                </Stack>
            </TableCell>
            <TableCell align="center">
                {isForDraftOrder &&
                <>
                    {singleItem.quantity}
                </>
                }

                {!isForDraftOrder &&
                <ProductQuantity
                    item={singleItem}
                />
                }
            </TableCell>
            <TableCell align="center">
                <Stack alignItems="center">
                    {singleItem?.product_price && singleItem?.quantity && (
                        <Typography variant="subtitle1">
                            {(singleItem?.product_price * singleItem?.quantity)
                                .toLocaleString(
                                    'en-US',
                                    {
                                        style: 'currency',
                                        currency: 'USD'
                                    })
                            }
                        </Typography>
                    )}
                </Stack>
            </TableCell>
            <TableCell align="right">
                {!isForDraftOrder &&
                    <IconButton
                        onClick={() => setSelectedItemId(singleItem.id)}
                        size="medium"
                        sx={{opacity: 0.5, '&:hover': {bgcolor: 'transparent'}}}
                    >
                        <DeleteOutlineIcon style={{color: theme.palette.grey[500]}}/>
                    </IconButton>
                }
                {isForDraftOrder &&
                    <Checkbox
                        checked={isSelected}
                        onChange={() => checkboxClickHandler(singleItem.id)}
                    />
                }
            </TableCell>
        </TableRow>
    );
};

export default OrderItemCartEntity;