import * as React from 'react';
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import CustomOrdersList from "./CustomOrdersList";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const CustomOrdersPage = () => {
    const activeStore = useSelector(store => store.stores.activeStore);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!activeStore) {
            return;
        }

        if (!activeStore.enable_custom_quotes) {
            navigate('/', {replace: true});
        }
    }, [activeStore]);

    return (
        <DefaultLayout>
            <CustomOrdersList/>
        </DefaultLayout>
    );
};

export default CustomOrdersPage;