import * as React from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {CUSTOM_ORDER_VERSION_STATUSES} from "../../utils/helpers";
import { blueGrey, blue, red, green } from '@mui/material/colors';
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import Skeleton from "@mui/material/Skeleton";

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomOrderVersions = ({ versions, selectedVersion, setSelectedVersion, isLoaded, currentUser }) => {
    const theme = useTheme();

    const getBackgroundColor = (status) => {
        switch (status) {
            case CUSTOM_ORDER_VERSION_STATUSES.WAITING_FEEDBACK:
                return blue[500];
            case CUSTOM_ORDER_VERSION_STATUSES.FEEDBACK_SUBMITTED:
                return blueGrey[500];
            case CUSTOM_ORDER_VERSION_STATUSES.APPROVED:
                return green[500];
            default:
                return red[500];
        }
    };

    return (
        <Paper
            variant={'outlined'}
        >
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 600,
                    padding: '16px'
                }}
            >
                Versions List
            </Typography>

            <Divider />

            <Stack
                sx={{
                    padding: '8px 0'
                }}
                spacing={1}
            >
                {!isLoaded &&
                    [1, 2].map(value =>
                        (<Skeleton
                            key={value}
                            animation={'wave'}
                            variant={'rectangle'}
                            height={70}
                        />)
                    )
                }

                {versions?.map((version, index) => {
                    const publishingDate = dayjs.utc(version.published_at).tz(currentUser.timezone).format('MM/DD/YYYY');
                    return (
                        <React.Fragment key={index}>
                            <Grid
                                container
                                sx={{
                                    padding: '8px 16px',
                                    backgroundColor: (selectedVersion?.id === version.id) ? theme.palette.secondary.light : ''
                                }}
                                onClick={() => {setSelectedVersion(version)}}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Stack spacing={1}>
                                        <Typography fontWeight={600}>
                                            Version {version.name}
                                        </Typography>

                                        <Box>
                                            <Box component="span" sx={{ color: theme.palette.secondary.main, marginRight: '4px' }}>
                                                Created:
                                            </Box>
                                            <Box component="span">
                                                {publishingDate}
                                            </Box>
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}
                                      sx={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center'
                                      }}
                                >
                                    <Stack
                                        sx={{
                                            minWidth: '150px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mb: 1
                                            }}
                                        >
                                            <Box component="span" sx={{ color: theme.palette.secondary.main, marginRight: '4px' }}>
                                                Price:
                                            </Box>
                                            <Box component="span">
                                                ${version.price}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'inline-block',
                                                padding: '1px 4px',
                                                backgroundColor: getBackgroundColor(version.status),
                                                color: '#fff',
                                                borderRadius: '4px',
                                                textAlign: 'center',

                                            }}
                                        >
                                            {version.status
                                                .split(' ')
                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                                .join(' ')
                                            }
                                        </Box>
                                    </Stack>
                                </Grid>

                            </Grid>

                            <Divider variant="middle" component="div" />
                        </React.Fragment>
                    )
                })}

            </Stack>
        </Paper>
    );
};

export default CustomOrderVersions;