import * as React from 'react';

import {getCustomOrderFormsConfigAction} from '../../store/actions/customOrders'
import {getJsonObject} from "../../utils/helpers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';

//import data from "./temp";

import {generateOptionElement, getRequiredFieldsCounts} from "../../components/dynamicForms/utils";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import DynamicFormContent from "../../components/dynamicForms/DynamicFormContent";
import {useSelector} from "react-redux";
import CancellationDialog from "../checkout/components/CancellationDialog";
import OrderCanceledDialog from "../checkout/components/OrderCanceledDialog";


const AddCustomOrderDialog = ({ createCustomOrderHandler, handleClose }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tooltip, setTooltip] = React.useState({type: 'text', value: ''});
    const formsConfig = useSelector(store => store.customOrders.formsConfig);

    const [openCancellationDialog, setOpenCancellationDialog] = React.useState(false);
    const [openOrderCanceledDialog, setOpenOrderCanceledDialog] = React.useState(false);
    const [enteredOptions, setEnteredOptions] = React.useState({});

    const config = React.useMemo(() => getJsonObject(formsConfig)?.mainForm, [formsConfig]);
    //const config = data.mainForm;

    const requiredFieldsCounts = getRequiredFieldsCounts(enteredOptions, config);
    const disableSaveButtons = requiredFieldsCounts.filled < requiredFieldsCounts.required;

    React.useEffect(() => {
        const getFormsConfig = async () => {
            if (!config)
            await getCustomOrderFormsConfigAction();
        };

        getFormsConfig();
    }, []);


    const createOrderHandler = async () => {
        await createCustomOrderHandler(enteredOptions);
        setOpenCancellationDialog(false);
    };

    return (
        <>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={true}
                fullWidth={true}
            >
                <DialogTitle id="customized-dialog-title">
                    <Typography
                        variant={'body1'}
                        sx={{
                            fontWeight: 600
                        }}
                    >
                        {config?.title}
                    </Typography>
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <DynamicFormContent
                        form={config}
                        generateElement={(option) => generateOptionElement(option, enteredOptions, setEnteredOptions, setAnchorEl, setTooltip, true)}
                    />
                </DialogContent>

                <DialogActions
                    sx={{
                        padding: '16px'
                    }}
                >
                    <Button
                        color={'error'}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"contained"}
                        onClick={() => {setOpenCancellationDialog(true)}}
                        disabled={disableSaveButtons}
                    >
                        Submit For Quotation
                    </Button>
                </DialogActions>

                {!!anchorEl &&
                <Popover
                    open={true}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {tooltip.type === 'img' ?
                        <Box component={'img'}
                             src={tooltip.value}
                             maxWidth={'300px'}
                             border={'1px solid black'}
                        />
                        :
                        <Typography sx={{ p: 2 }}>{tooltip.value}</Typography>
                    }
                </Popover>
                }

            </Dialog>

            {openCancellationDialog &&
                <CancellationDialog
                    mainText={'Quote submission in progress...'}
                    bottomText={'Do not refresh or close this page until your quote is submitted.'}
                    cancelButtonText={'Cancel Quote'}
                    createOrderHandler={createOrderHandler}
                    closeDialogHandler={() => {
                        setOpenCancellationDialog(false);
                        setOpenOrderCanceledDialog(true);
                    }}
                />
            }

            {openOrderCanceledDialog &&
                <OrderCanceledDialog
                    mainText={'Quote submission canceled.'}
                    closeDialogHandler={() => setOpenOrderCanceledDialog(false)}
                />
            }
        </>
    );
};

export default AddCustomOrderDialog;