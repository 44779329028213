import * as React from "react";
import { Navigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {STATE_STATUSES} from "../utils/helpers";

const AllowedForRolesRoute = ({allowedRoles, children }) => {
    const { authenticatedUser, status } = useSelector(store => store.auth);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if ([STATE_STATUSES.READY, STATE_STATUSES.ERROR].includes(status)) {
           setLoading(false);
        }
    }, [status]);

    if (loading) {
        return null;
    }

    return (allowedRoles || []).includes(authenticatedUser?.role?.name) ?
        (<>{children}</>) :
        (<Navigate to="/" replace/>);
};

export default AllowedForRolesRoute;