import * as React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddStoneDialog from "../../pages/customOrders/AddStoneDialog";
import Divider from "@mui/material/Divider";

const StoneListComponent = ({ state, option, onChangeHandler, stateFunction }) => {
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedStoneIndex, setSelectedStoneIndex] = React.useState(undefined);

    const createOrUpdateStone = (options) => {
        if (typeof(selectedStoneIndex) !== 'undefined') {
            const newStonesOptions = state[option.name] || [];
            newStonesOptions[selectedStoneIndex] = options;
            onChangeHandler(option, {target: {value: newStonesOptions}}, stateFunction);
        } else {
            onChangeHandler(option, {target: {value: [...(state[option.name] || []), options]}}, stateFunction);
        }
    };

    const saveHandler = (options) => {
        createOrUpdateStone(options);
        setOpenDialog(false);
    };

    const saveAndNewHandler = (options) => {
        createOrUpdateStone(options);
    };

    const deleteStoneHandler = (stoneIndex) => {
        const newStonesOptions = state[option.name] || [];
        newStonesOptions.splice(stoneIndex, 1);
        onChangeHandler(option, {target: {value: newStonesOptions}}, stateFunction);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600
                    }}
                >
                    Stone List
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            setSelectedStoneIndex(undefined);
                            setOpenDialog(true)
                        }
                    }
                >+ Add New</Button>
            </Box>

            <Divider variant="middle" component="div" />

            <Box>
                {state[option.name]?.map((stone, index) => (
                    <React.Fragment key={index}>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            mb={2}
                            mt={0}
                        >
                            {Object.keys(stone).map((key) => {
                                    if ((option?.hiddenParams || []).includes(key)) {
                                        return null;
                                    }

                                    if (Array.isArray(stone[key])) {
                                        return null;
                                    }

                                    return (
                                        <Grid item xs key={key}>
                                            <Typography>{stone[key]}</Typography>
                                        </Grid>
                                    );
                                }
                            )}
                            <Grid item>
                                <Link
                                    href="#"
                                    sx={{
                                        mr: 1,
                                        width: '45px',
                                        textAlign: 'center',
                                        display: "inline-block"
                                    }}
                                    onClick={
                                        () => {
                                            setSelectedStoneIndex(index);
                                            setOpenDialog(true);
                                        }
                                    }
                                >
                                    Edit
                                </Link>
                                <Link
                                    href="#"
                                    sx={{
                                        width: '45px',
                                        textAlign: 'center',
                                        display: "inline-block"
                                    }}
                                    color="error"
                                    onClick={
                                        () => {
                                            deleteStoneHandler(index);
                                        }
                                    }
                                >
                                    Delete
                                </Link>
                            </Grid>
                        </Grid>

                        <Divider variant="middle" component="div" />
                    </React.Fragment>
                ))}
            </Box>

            {openDialog &&
                <AddStoneDialog
                    stoneOptions={
                        typeof(selectedStoneIndex) !== 'undefined' ?
                            state[option.name][selectedStoneIndex] :
                            {}
                    }
                    closeHandler={() => {setOpenDialog(false);}}
                    saveAndNewHandler={saveAndNewHandler}
                    saveHandler={saveHandler}
                />
            }
        </Box>
    );
};

export default StoneListComponent;