import * as React from 'react';
import DeleteIconOutlined from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

const FeedbackForm = ({ formTitle, formDescription, selectedFiles, setSelectedFiles, comment, setComment,maxUploadedFilesNumber }) => {
    const [moreFilesThanAllowed, setMoreFilesThanAllowed] = React.useState(false);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const totalFilesNumber = files.length + selectedFiles.length;

        setMoreFilesThanAllowed(totalFilesNumber >= maxUploadedFilesNumber);

        setSelectedFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            let availableQuantity = maxUploadedFilesNumber - prevFiles.length;

            for (const singleFile of files) {
                if (availableQuantity <= 0) {
                    break;
                }

                newFiles.push(singleFile);
                availableQuantity -= 1;
            }

            return newFiles;
        });
    };

    const handleDeleteFile = (fileToDelete) => {
        setMoreFilesThanAllowed(false);
        setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    };


    // TODO: Need to add an ability to generate preview for HEIC images and for PDF-files
    // It can be done with  "heic2any"  and  "react-pdf"  libraries.

    return (
        <>
            <Typography
                variant={'h6'}
                sx={{
                    fontWeight: 600
                }}
            >
                {formTitle}
            </Typography>

            <Box
                sx={{
                    margin: '10px 0'
                }}
            >
                <Typography
                    variant={'body1'}
                >
                    {formDescription}
                </Typography>
            </Box>

            <Paper
                variant={'outlined'}
                sx={{
                    padding: '16px',
                }}
            >
                <Stack spacing={2}>
                    <Button
                        sx={{
                            alignSelf: 'start'
                        }}
                        component="label"
                        variant="outlined"
                        startIcon={<FileUploadOutlinedIcon />}
                        disabled={moreFilesThanAllowed}
                    >
                        Click to Upload
                        <input
                            type="file"
                            multiple
                            accept="image/*,video/*"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>

                    {moreFilesThanAllowed &&
                    <Typography
                        variant={'body1'}
                        color={'red'}
                    >
                        You can't upload more files.
                    </Typography>
                    }

                    <Stack spacing={1}>
                        {selectedFiles.map((file, index) => (
                            <Card
                                key={index}
                                variant={'outlined'}
                                sx={{
                                    padding: '5px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CardMedia
                                        component={file.type.startsWith('video') ? 'video' : 'img'}
                                        src={URL.createObjectURL(file)}
                                        controls={file.type.startsWith('video')}
                                        alt={file.name}
                                        sx={{
                                            width: '50px',
                                            height: '50px',
                                            marginRight: '5px'
                                        }}
                                    />

                                    <Typography
                                        variant="h6"
                                    >
                                        {file.name}
                                    </Typography>
                                </Box>


                                <IconButton
                                    onClick={() => handleDeleteFile(file)}
                                    aria-label="Delete"
                                >
                                    <DeleteIconOutlined />
                                </IconButton>
                            </Card>
                        ))}
                    </Stack>

                    <Typography
                        color={'secondary'}
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        Recommended resolution is 640x640 with file size
                    </Typography>

                    <Box>
                        <Typography
                            variant="h6"
                            color={'secondary'}
                            sx={{
                                marginBottom: '5px'
                            }}
                        >
                            Additional Details
                        </Typography>
                        <TextField
                            value={comment}
                            onChange={(e) => {setComment(e.target.value)}}
                            multiline
                            rows={4}
                            fullWidth
                            sx={{
                                '& > div': {
                                    padding: '5px 10px'
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </Paper>
        </>
    );
};

export default FeedbackForm;