import * as React from 'react';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "@mui/material/Checkbox";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/Check';
import {getJsonObject} from "../../utils/helpers";
import {Typography} from "@mui/material";
import FilesUploaderComponent from "../../components/dynamicForms/FilesUploaderComponent";
import SelectComponent from "../../components/dynamicForms/SelectComponent";
import DatePickerComponent from "../../components/dynamicForms/DatePickerComponent";
import TimePickerComponent from "../../components/dynamicForms/TimePickerComponent";
import AddressOptionComponent from "../../components/dynamicForms/AddressOptionComponent";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import StoneListComponent from "../../components/dynamicForms/StoneListComponent";

export const getCaptionForOption = option => {
    if (typeof(option?.displayName) !== 'undefined') {
        return option?.displayName ? option.displayName : undefined;
    }

    return option?.name.charAt(0).toUpperCase() + option?.name.slice(1) || 'Unknown option';
};

export const getInitialStateByDefaultValues = (options) => {
    return (options || []).reduce((initialState, option) => {
        if (option.defaultValue !== undefined) {
            initialState[option.name] = option.defaultValue;
        }

        return initialState;
    }, {});
};

const getTextFieldError = (value, option) => {
    if (!option.rules) {
        return {
            hasError: false,
            message: undefined
        };
    }

    for (let rule of option.rules) {
        const ruleString = (typeof (rule) !== 'string') ? rule.rule : rule;
        const re = new RegExp(ruleString);

        if (!re.test(value)) {
            return {
                hasError: true,
                message: rule.message ? rule.message : undefined
            }
        }
    }

    return {
        hasError: false,
        message: undefined
    };
};

export const getRequiredFieldsCounts = (state, config) => {
    const calcFieldsForSingleSection = (state, options) => {
        return (options || []).reduce((result, item) => {
            if (item.required && item.type !== 'checkbox') {
                result.required++;

                if (item.type === 'date' && state[item.name] === 'Invalid Date') {
                    return result;
                }

                if (item.type === 'time' && state[item.name] === 'Invalid Time') {
                    return result;
                }

                const error = getTextFieldError(state[item.name], item);

                if (item.rules && error.hasError) {
                    return result;
                }

                if (state[item.name] &&
                    (Array.isArray(state[item.name]) ? state[item.name].length > 0 : true)) {
                    result.filled++;
                }
            }

            return result;
        }, { filled: 0, required: 0 });
    };

    if (config?.['sections']) {
        return config['sections'].reduce((result, section) => {
            const singleSectionValues = calcFieldsForSingleSection(state, section['options']);
            result.required += singleSectionValues.required;
            result.filled += singleSectionValues.filled;

            return result;
        }, {filled: 0, required: 0});
    }

    return calcFieldsForSingleSection(state, config);

};

const onChangeHandler = (option, event, stateFunction) => {
    if (option.type !== 'checkbox') {
        event.preventDefault?.();
    }

    stateFunction(
        prev => {
            if (!(option.type !== 'checkbox' ? event.target.value : event.target.checked)) {
                const stateCopy = {...prev};
                delete stateCopy[option.name];
                return stateCopy;
            }

            return ({
                ...prev,
                [option.name]: (option.type === 'checkbox' ? true : event.target.value)
            });
        }
    );
};

export const generateOptionElement = (option, state, stateFunction, setAnchor, setTooltip, isForCustomOrderForm = false) => {
    const caption = getCaptionForOption(option);
    let component = null;


    switch (option.type) {
        case "select": {
            component = <SelectComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;
        }

        case "date": {
            component = <DatePickerComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;
        }

        case "time": {
            component = <TimePickerComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;
        }

        case "checkbox": {
            component = <FormControlLabel
                sx={{
                    width: "100%"
                }}
                name={option.name}
                size={"small"}
                control={<Checkbox />}
                checked={!!state[option.name]}
                onChange={event => onChangeHandler(option, event, stateFunction)}
            />;
            break;
        }

        case "address":
            component = <AddressOptionComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;

        case "files":
            component = <FilesUploaderComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;

        case "stonesList":
            component = <StoneListComponent
                state={state}
                option={option}
                stateFunction={stateFunction}
                onChangeHandler={onChangeHandler}
            />;
            break;

        default: {
            const error = getTextFieldError(state[option.name], option);

            component = <TextField
                sx={{
                    width: "100%"
                }}
                size={"small"}
                variant="outlined"
                multiline={option.rows > 1}
                rows={option.rows}
                required={!!option?.required}
                error={Boolean(state[option.name]) && error.hasError}
                helperText={state[option.name] ? !error.hasError ? '' : error.message || 'Invalid characters entered' : ''}
                value={state[option.name] || ""}
                inputProps={{
                    maxLength: option.maxLength ? option.maxLength : undefined
                }}
                onChange={event => onChangeHandler(option, event, stateFunction)}

            />;
        }
    }

    let captionComponent;

    if (option.tooltip) {
        captionComponent =
            <Badge
                sx={{
                    cursor: 'pointer'
                }}
                badgeContent={'?'}
                color={'primary'}
                size={'small'}
                onClick={(e) => {
                    setTooltip({
                        type: option.tooltip.type || 'img',
                        value: option.tooltip.value || option.tooltip
                    });
                    setAnchor(e.currentTarget);
                }}
            >
                {caption ? caption : null}
            </Badge>
    }
    else {
        captionComponent = caption ? caption : null;
    }

    const error = getTextFieldError(state[option.name], option);

    const isCheckIconHidden =
        typeof(state[option.name]) === 'undefined' ||
        state[option.name] === '' ||
        (option.type === 'checkbox' && state[option.name] === false) ||
        (option.type === 'date' && state[option.name] === 'Invalid Date') ||
        (option.type === 'time' && state[option.name] === 'Invalid Time') ||
        (option.rules && error.hasError)
    ;

    if (!isForCustomOrderForm) {
        return (
            <Grid container>
                <Grid item xs={12} sm={5}
                      sx={{
                          display: 'flex',
                          alignItems: 'center',
                          '& .MuiBadge-badge': {
                              right: option?.required ? '-20px' : '-10px',
                              top: 0,
                              padding: '0 4px',
                          }
                      }}
                >
                    <CheckIcon
                        sx={{
                            color: 'green',
                            marginRight: '5px',
                            visibility: isCheckIconHidden ? 'hidden' : 'visible'
                        }}
                    />
                    {captionComponent}
                    {option?.required &&
                        <Typography
                            variant={'h5'}
                            sx={{
                                marginLeft: '3px',
                                color: 'red'
                            }}
                        >
                            *
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={7}>{component}</Grid>
            </Grid>
        );
    }

    return (
        <Stack>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "start",

                    '& .MuiBadge-badge': {
                        right: option?.required ? '-20px' : '-10px',
                        top: 0,
                        padding: '0 4px',
                    }
                }}
            >
                {captionComponent}
                {option?.required &&
                    <Typography
                        variant={'h5'}
                        sx={{
                            marginLeft: '3px',
                            color: 'red'
                        }}
                    >
                        *
                    </Typography>
                }
            </Box>
        {component}
        </Stack>
    );
};

export const generateSelectedOptionsWithValues = (item) => {
    const selectedOptions = getJsonObject(item.options);
    const originalOptions = getJsonObject(item.product.options)?.options;
    const internalFields = getJsonObject(item.product.options)?.internalFields;

    let result = Object.entries(selectedOptions).map(([key, selectedValue]) => {
        let title;

        if ((internalFields || []).includes(key)) {
            return null;
        }

        if (['Lat', 'Lng', 'Zoom'].includes(key)) {
            title = key;
        } else {
            const originalOption = originalOptions.find(originalOption => originalOption.name === key);
            const originalVariant = originalOption?.variants?.find(variant => variant.value === selectedValue);
            title = getCaptionForOption(originalOption);
            selectedValue = originalVariant ? originalVariant.caption : selectedValue;
        }

        return {
            title: title,
            value: selectedValue
        };
    }).filter(Boolean);

    return result.sort((a, b) => {
        if (a.title > b.title) {
            return 1;
        }

        if (a.title < b.title) {
            return -1;
        }

        return 0;
    });
};