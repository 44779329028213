import * as React from 'react';
import DefaultLayout from "../../components/layouts/DefaultLayout/DefaultLayout";
import FeedbackDialog from "./FeedbackDialog";
import Button from "@mui/material/Button";
import ShippingAddressDialog from "./ShippingAddressDialog";
import {useSelector} from "react-redux";
import CancellationDialog from "../checkout/components/CancellationDialog";
import OrderCreatedDialog from "../checkout/components/OrderCreatedDialog";
import OrderCanceledDialog from "../checkout/components/OrderCanceledDialog";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import SharePresentationLinkDialog from "./SharePresentationLinkDialog";
import CustomOrderVersions from "./CustomOrderVersions";
import SubmissionInfo from "./SubmissionInfo";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import OriginalSubmissionDialog from "./OriginalSubmissionDialog";
import {useNavigate} from "react-router-dom";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material";
import { useParams } from 'react-router-dom';
import {getCustomOrderAction} from "../../store/actions/customOrders";
import VersionMedia from "./VersionMedia";
import VersionDetails from "./VersionDetails";
import VersionFeedbacks from "./VersionFeedback";


const CustomOrderDetailsPage = () => {
    const customOrder = useSelector(store => store.customOrders.selectedItem);
    const activeStore = useSelector(store => store.stores.activeStore);
    const currentUser = useSelector(store => store.auth.authenticatedUser);

    const { id } = useParams();

    const [recipient, setRecipient] = React.useState('store');

    const shippingAddress = recipient === 'company' ?
        activeStore?.company.shipping_address : activeStore?.shipping_address;

    const [isCustomOrderLoaded, setIsCustomOrderLoaded] = React.useState(false);
    const [openFeedbackDialog, setOpenFeedbackDialog] = React.useState(false);
    const [openSharePresentationLinkDialog, setOpenSharePresentationLinkDialog] = React.useState(false);
    const [openOriginalSubmissionDialog, setOpenOriginalSubmissionDialog] = React.useState(false);
    const [openShippingAddressDialog, setOpenShippingAddressDialog] = React.useState(false);
    const [openCancellationDialog, setOpenCancellationDialog] = React.useState(false);
    const [openOrderCanceledDialog, setOpenOrderCanceledDialog] = React.useState(false);
    const [openOrderCreatedDialog, setOpenOrderCreatedDialog] = React.useState(false);

    const [selectedVersion, setSelectedVersion] = React.useState(undefined);

    const theme = useTheme();

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!activeStore) {
            return;
        }

        if (!activeStore.enable_custom_quotes) {
            navigate('/', {replace: true});
        }
    }, [activeStore]);

    React.useEffect(() => {
       const getCustomOrder = async () => {
           try {
               await getCustomOrderAction(id);
               setIsCustomOrderLoaded(true);
           } catch (exception) {

           }
       };

       getCustomOrder();
    }, [id]);

    React.useEffect(() => {
        setSelectedVersion(customOrder?.versions?.[0])
    }, [customOrder]);

    const createOrderHandler = async () => {
        setOpenCancellationDialog(false);
        setOpenOrderCreatedDialog(true);
    };

    return (
        <DefaultLayout>
            <Grid container>
                <Grid
                    item
                    xs={3}
                >
                    <Stack spacing={2}>
                        < SubmissionInfo
                            currentUser={currentUser}
                            customOrder={customOrder}
                        />

                        <CustomOrderVersions
                            isLoaded={isCustomOrderLoaded}
                            currentUser={currentUser}
                            versions={customOrder.versions}
                            selectedVersion={selectedVersion}
                            setSelectedVersion={setSelectedVersion}
                        />
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={9}
                    sx={{
                        padding: '0 16px'
                    }}
                >
                    <Paper
                        variant={'outlined'}
                        sx={{
                            height: '100%'
                        }}
                    >

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '10px 16px',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                variant={'h4'}
                            >
                                Viewing: Version {selectedVersion ? selectedVersion.name : '-'}
                            </Typography>
                            <Stack spacing={1} direction={'row'}>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontWeight: '600'
                                    }}
                                    disabled={!isCustomOrderLoaded}
                                    onClick={() => {
                                        setOpenOriginalSubmissionDialog(true);
                                    }}
                                >
                                    View Original Submission
                                </Button>

                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        fontWeight: '600'
                                    }}
                                    disabled={!isCustomOrderLoaded}
                                    onClick={() => {
                                        setOpenSharePresentationLinkDialog(true);
                                    }}
                                >
                                    Share Presentation Link
                                </Button>

                                {selectedVersion?.feedbacks?.length === 0 &&
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        sx={{
                                            color: theme.palette.text.secondary,
                                            fontWeight: '600'
                                        }}
                                        disabled={!isCustomOrderLoaded}
                                        onClick={() => {
                                            setOpenFeedbackDialog(true);
                                        }}
                                    >
                                        Submit Feedback
                                    </Button>
                                }

                                <Button
                                    variant={'contained'}
                                    disabled={!isCustomOrderLoaded}
                                    onClick={() => {
                                        setOpenShippingAddressDialog(true);
                                    }}
                                >
                                    Approve & Order
                                </Button>
                            </Stack>
                        </Box>

                        <Divider />

                        <Grid container>
                            <Grid
                                item
                                xs={6}
                            >
                                <VersionMedia/>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Stack
                                    spacing={2}
                                >
                                    {selectedVersion?.feedbacks?.length > 0 &&
                                        <VersionFeedbacks/>
                                    }
                                    <VersionDetails/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>


            {openFeedbackDialog &&
                <FeedbackDialog
                    title={'Feedback Submission for version XXX'}
                    closeHandler={() => setOpenFeedbackDialog(false)}
                    successHandler={() => setOpenFeedbackDialog(false)}
                    maxUploadedFilesNumber={3}
                />
            }

            {openShippingAddressDialog &&
                <ShippingAddressDialog
                    shippingAddress={shippingAddress}
                    activeStore={activeStore}
                    recipient={recipient}
                    changeRecipientHandler={setRecipient}
                    closeHandler={() => setOpenShippingAddressDialog(false)}
                    successHandler={() => {
                        setOpenShippingAddressDialog(false);
                        setOpenCancellationDialog(true);
                    }}
                />
            }

            {openCancellationDialog &&
            <CancellationDialog
                mainText={'Order submission in progress...'}
                bottomText={'Do not refresh or close this page until your order is submitted.'}
                cancelButtonText={'Cancel Order'}
                createOrderHandler={async () => await createOrderHandler()}
                closeDialogHandler={() => {
                    setOpenCancellationDialog(false);
                    setOpenOrderCanceledDialog(true);
                }}
            />
            }

            {openOrderCreatedDialog &&
            <OrderCreatedDialog
                redirectPath={'/orders-history'}
                mainText={'Your order has been placed!'}
                bottomText={'Redirecting you to the orders history page.'}
                showProgressBar={true}
            />
            }

            {openOrderCanceledDialog &&
                <OrderCanceledDialog
                    mainText={'Canceling order submission...'}
                    closeDialogHandler={() => setOpenOrderCanceledDialog(false)}
                />
            }

            {openSharePresentationLinkDialog &&
                <SharePresentationLinkDialog
                    link={'https://google.com'}
                    closeHandler={() => setOpenSharePresentationLinkDialog(false)}
                />
            }

            {openOriginalSubmissionDialog &&
                <OriginalSubmissionDialog
                    closeHandler={() => setOpenOriginalSubmissionDialog(false)}
                />
            }

        </DefaultLayout>
    );
};

export default CustomOrderDetailsPage;