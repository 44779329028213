import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

const OriginalSubmissionDialog = ({closeHandler}) => {
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth={true}
        >
            <DialogTitle id="customized-dialog-title">
                <Typography
                    variant={'h4'}
                    sx={{
                        fontWeight: 600
                    }}
                >
                    Original Submission
                </Typography>
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                Here can be your ADS.
            </DialogContent>
        </Dialog>);
};

export default OriginalSubmissionDialog;