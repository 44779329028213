import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FeedbackForm from "./FeedbackForm";

const FeedbackDialog = ({ title, closeHandler, successHandler, maxUploadedFilesNumber = 4}) => {
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [comment, setComment] = React.useState('');

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={true}
            fullWidth={'md'}
        >
            <DialogTitle id="customized-dialog-title">
                <Typography
                    variant={'h6'}
                    sx={{
                        fontWeight: 600
                    }}
                >
                    {title}
                </Typography>
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <FeedbackForm
                    formTitle={'Supporting Details'}
                    formDescription={'Some description text here.'}
                    maxUploadedFilesNumber={maxUploadedFilesNumber}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    comment={comment}
                    setComment={setComment}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: '16px'
                }}
            >
                <Button
                    color={'error'}
                    onClick={closeHandler}
                >
                    Cancel
                </Button>
                <Button
                    variant={"contained"}
                    onClick={closeHandler}
                    disabled={selectedFiles.length === 0 && comment.trim() === ''}
                >
                    Submit Your Feedback
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FeedbackDialog;